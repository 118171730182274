import React from "react"

import SpotlightBanner from "../components/SpotlightBanner"
// import GoogleMap from  "../components/GoogleMap";
import accessMapImg from "../images/access-map.jpg"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO/seo"
import { siteData } from "../data/siteData"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

const AccessPage = () => {
  const data = useStaticQuery(graphql`
    query SpotlightBannerAccessImageQuery {
      spotlightBannerImageImage: file(
        relativePath: { eq: "access-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO metaData={siteData.accessPage} siteData={siteData}/>
        {/* <!-- Banner section --> */}

        <SpotlightBanner
          bannerImage={data.spotlightBannerImageImage.childImageSharp.fluid}
          title="アクセス"
        />

        {/* <!-- Access static text --> */}
        <section className="access-page-section inner-padding">
          <div className="container">
            <div className="row access-page-row">
              <div className="col-xs-12 access-box text-center">
                <h3 className="title">アメリカンイングリッシュハウス</h3>
                <Link
                  to={siteData.site.map.url}
                  target="_blank"
                >
                  <h4 className="title semibold mb-5">
                    {siteData.site.addressPostCode}{" "}{siteData.site.address}{" "}
                  </h4>
                </Link>
                <p>{siteData.site.addressDirections}</p>
                <h4 className="mb-25 mt-15 semibold">
                  <ScrollAnimation
                    // offset={80}
                    animateIn="bounce"
                    duration={1}
                    animateOnce={true}
                  >
                    <Link href={`tel:${siteData.site.contactPhone}`}>
                      <i className="icon icon-call-red" />
                      {siteData.site.contactPhone}
                    </Link>
                  </ScrollAnimation>
                </h4>
                <p>
                  ※カーナビは住所で検索してください。（電話番号で検索すると旧住所が表示される場合があります）
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Access static text --> */}
        <section className="access-map-section inner-padding extra-bottom">
          <div className="container">
            <div className="row access-map-row">
              <div className="col-xs-12">
                <Link
                  to={siteData.site.map.url}
                  target="_blank"
                  className="map-link"
                >
                  <img className="map-img" src={accessMapImg} alt="Map" />
                  {/* <GoogleMap 
                            // className="map-img"
                            /> */}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default AccessPage
